import styled from "styled-components";
import React from "react";
import {
  H2,
  H4,
  H6,
  Space1,
  Space2,
  UnstyledLink,
  Paragraph3,
  PageContainer,
} from "./Typography";
import axios from "./img/media_logos/Axios.svg";
import barrons from "./img/media_logos/barrons.svg";
import bloomberg from "./img/media_logos/Bloomberg.svg";
import businessInsider from "./img/media_logos/Business-Insider.svg";
import cnn from "./img/media_logos/CNN.svg";
import forbes from "./img/media_logos/Forbes.svg";
import foxNews from "./img/media_logos/Fox-News.svg";
import laTimes from "./img/media_logos/LATimes.svg";
import npr from "./img/media_logos/NPR.svg";
import politico from "./img/media_logos/Politico.svg";
import nyt from "./img/media_logos/NYT.svg";
import wsj from "./img/media_logos/WSJ.svg";
import washingtonPost from "./img/media_logos/Washington-Post.svg";
import yahooFinance from "./img/media_logos/Yahoo-Finance.svg";
import businessWire from "./img/media_logos/business-wire.svg";
import foreignPolicy from "./img/media_logos/ForeignPolicy.svg";
import marketWatch from "./img/media_logos/MarketWatch.svg";
import usNews from "./img/media_logos/USNews.svg";
import FT from "./img/media_logos/FT.svg";
import fitch from "./img/media_logos/fitch.svg";
import NewzRoomAfrika from "./img/media_logos/Newzroom_Afrika.png";
import TechCrunch from "./img/media_logos/TechCrunch.svg";
import reuters from "./img/media_logos/Reuters.svg";
import berneUnion from "./img/media_logos/berne-union.svg";
import rteRadio1 from "./img/media_logos/RTE-Radio1.svg";
import { up, only } from "styled-breakpoints";
import { orderBy } from "lodash/fp";

const Press = React.memo(() => {
  return (
    <PageContainer>
      <Content>
        <Space1 />
        <H2>Press</H2>
        <Space1 />
        {articles.map((article) => (
          <UnstyledLink key={article.url} href={article.url} target="_blank">
            <Article>
              <ArticleRightSide>
                <H4>{article.title}</H4>
                <div style={{ display: "flex" }}>
                  <Paragraph3>{article.source}</Paragraph3>
                  <Separator>
                    <Paragraph3>|</Paragraph3>
                  </Separator>
                  <Paragraph3>{article.date}</Paragraph3>
                </div>
              </ArticleRightSide>
              <ArticleLeftSide>
                {article.logo && (
                  <Image
                    src={article.logo}
                    alt={article.source}
                    width={article.logoWidth ? article.logoWidth : "auto"}
                  />
                )}
                {article.textLogo && <H6>{article.textLogo}</H6>}
              </ArticleLeftSide>
            </Article>
            <Space2 />
          </UnstyledLink>
        ))}
        <Space2 />
      </Content>
    </PageContainer>
  );
});

const ArticleRightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid transparent;
  padding-bottom: 1rem;
  text-align: left;
  flex: 1;
`;

const Article = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  &:hover ${ArticleRightSide} {
    border-bottom-color: #ff6363;
  }
  ${only("mobile")} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Content = styled.div`
  max-width: 800px;

  ${up("desktop")} {
    margin-left: 180px;
  }
`;

const ArticleLeftSide = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 220px;
  ${only("mobile")} {
    justify-content: flex-start;
  }
`;

const Image = styled.img`
  max-width: 100%;
  opacity: 0.8;
`;

const Separator = styled.div`
  width: 1rem;
  display: flex;
  justify-content: center;
`;

//todo: make date a real Date
const articles = orderBy(
  (article) => new Date(article.date).getTime(),
  "desc",
  [
    {
      title: "An unpredictable America looks more and more like an emerging market",
      source: "Financial Times",
      date: "Oct 1, 2024",
      logo: FT,
      url: "https://www.ft.com/content/5f83a3fc-74e6-4b00-a3cd-aa9a81bb21a7",
    },
    {
      title: "Competing US-China Defense Tactics Dominate Singapore Forum",
      source: "Bloomberg",
      date: "Jun 3, 2024",
      logo: bloomberg,
      url: "video?url=https://www.youtube.com/watch?v=tFXzhjJCxN8?t=55m0s&title=Competing US-China Defense Tactics Dominate Singapore Forum",
    },
    {
      title:
        "Trump shooting offers test for stock market as investors weigh his election odds now",
      source: "MarketWatch",
      date: "Jul 14, 2023",
      logo: marketWatch,
      url: "https://www.marketwatch.com/story/for-stock-market-trump-rally-shooting-pits-election-odds-vs-haven-demand-3652e298?mod=william-watts",
    },
    {
      title:
        "State Street to Provide Data-Driven Geopolitical Risk Insights to Clients",
      source: "Business Wire",
      date: "Jul 11, 2023",
      logo: businessWire,
      url: "https://www.businesswire.com/news/home/20230711798808/en/State-Street-to-Provide-Data-Driven-Geopolitical-Risk-Insights-to-Clients",
    },
    {
      url: "https://www.barrons.com/articles/election-denial-economics-stock-market-midterms-51667933897",
      title: "Election Denial Will Weigh on Markets",
      source: "Barron's",
      date: "Nov 8, 2022",
      logo: barrons,
      logoWidth: 150,
    },
    {
      url: "https://www.rte.ie/radio/radio1/clips/22152052/",
      title: "UK and US Economies",
      source: "RTÉ Radio 1",
      date: "Oct 1, 2022",
      logo: rteRadio1,
      logoWidth: 120,
    },
    {
      url: "https://www.berneunion.org/Articles/Details/708/Expert-opinion-Political-and-social-risk-overview-for-Sub-Saharan-Africa",
      title: "Political and social risk overview for Sub-Saharan Africa",
      source: "Berne Union",
      date: "Sep 6, 2022",
      logo: berneUnion,
      logoWidth: 120,
    },
    {
      url: "https://www.ft.com/content/7b40420e-c678-4400-a4bd-ccdbbc44557e",
      title: "Is the US starting to resemble an emerging market?",
      source: "Financial Times",
      date: "Jul 11, 2022",
      logo: FT,
    },
    {
      url: "https://www.fitchsolutions.com/press-releases/fitch-group-completes-acquisition-geoquant",
      title: "Fitch Group Completes Acquisition of GeoQuant",
      source: "Fitch Solutions",
      date: "Jun 21, 2022",
      logo: fitch,
    },
    {
      url: "https://www.ft.com/content/2e1d032a-4a21-4814-9b73-126dd5d054a6",
      title: "Business appears complacent about US democratic stability",
      source: "Financial Times",
      date: "Dec 23, 2021",
      logo: FT,
    },
    {
      url: "https://www.nytimes.com/2021/12/13/business/didi-wall-street-china.html",
      title:
        "With Didi Leaving Wall Street, Trading Edges Closer to Beijing’s Thumb",
      source: "The New York Times",
      date: "Dec 13, 2021",
      logo: nyt,
    },
    {
      url: "https://www.ft.com/content/8f9543f4-4aa5-4853-80a8-2ed218784140",
      title: "Armed civilians at US protests: ‘A threshold has been crossed",
      source: "Financial Times",
      date: "Aug 28, 2020",
      logo: FT,
    },
    {
      url: "https://techcrunch.com/2017/06/20/global-risk-analysis-gets-an-artificial-intelligence-upgrade-with-geoquant",
      title:
        "Global risk analysis gets an artificial intelligence upgrade with GeoQuant",
      source: "Techcrunch",
      date: "June 20, 2017",
      logo: TechCrunch,
    },
    {
      title: "How GeoQuant Uses Computer Science to Calculate Political Risk",
      source: "Bloomberg",
      date: "May 17, 2019",
      logo: bloomberg,
      url: "https://www.bloomberg.com/news/videos/2019-05-17/how-geoquant-uses-computer-science-to-calculate-political-risk-video",
    },
    {
      title: "Top Global Political Risks in 2020",
      source: "USnews",
      date: "Feb 20, 2020",
      logo: usNews,
      url: "https://www.usnews.com/news/best-countries/slideshows/donald-trump-re-election-deemed-a-major-global-risk-for-2020",
    },
    {
      title:
        "This political-risk gauge just hit a 15-year high — and here’s what it means for the stock market",
      source: "MarketWatch",
      date: "Mar 19, 2018",
      logo: marketWatch,
      url: "https://www.marketwatch.com/story/this-political-risk-gauge-just-hit-a-15-year-high-heres-what-it-means-for-the-stock-market-2018-03-16",
    },
    {
      title: 'GeoQuant CEO Mark Rosenberg on CNN "Quest Means Business"',
      source: "CNN",
      date: "Mar 5, 2018",
      logo: cnn,
      url: "https://www.youtube.com/watch?v=IIQW607b3B4",
    },
    {
      title: "Global political risk forecast to fall in 2020: STUDY",
      source: "Yahoo Finance",
      date: "Feb 13, 2020",
      logo: yahooFinance,
      url: "https://money.yahoo.com/video/global-political-risk-forecast-fall-194537333.html",
    },
    {
      title: "The Daily Shot: The Wuhan Pneumonia Virus Spooks Global Markets",
      source: "The Wall Street Journal ",
      date: "Jan 21, 2020",
      logo: wsj,
      url: "https://blogs.wsj.com/dailyshot/2020/01/21/the-daily-shot-the-wuhan-virus-spooks-global-markets/#GlobalDevelopments",
    },
    {
      title: "GeoQuant CEO, Mark Rosenberg | Forbes Contributor",
      source: "Forbes",
      date: "Feb 12, 2019",
      logo: forbes,
      url: "https://www.forbes.com/sites/markrosenberg/2019/02/12/united-states-shutdown-risk-rises-mueller-risk-approaches-new-peak/#1179ed81243e",
    },
    {
      title:
        "The Finance 202: Today will mark the longest stock rally in U.S. history. Yes, despite the Trump turmoil.",
      source: "The Washington Post",
      date: "Aug 22, 2018",
      logo: washingtonPost,
      url: "https://www.washingtonpost.com/news/powerpost/paloma/the-finance-202/2018/08/22/the-finance-202-today-will-mark-the-longest-stock-rally-in-u-s-history-yes-despite-the-trump-turmoil/5b7c43901b326b7234392ab4/",
    },
    {
      title: "Tit-For-Tat Becomes the Norm as U.S., China Dig In for Trade War",
      source: "Business Insider ",
      date: "Aug 08, 2018",
      logo: bloomberg,
      url: "https://www.bloomberg.com/news/articles/2018-08-03/tit-for-tat-becomes-the-norm-as-u-s-china-dig-in-for-trade-war",
    },
    {
      title: "GeoQuant CEO Appears on Bloomberg BNN To Discuss Trade",
      source: "BNN",
      date: "Aug 07, 2018",
      logo: bloomberg,
      url: "https://vimeo.com/283793802/21599193a3",
    },
    {
      title:
        "China targets $60 billion of U.S. goods as two sides dig deeper on trade war",
      source: "Los Angeles Times",
      date: "Aug 03, 2018",
      logo: laTimes,
      url: "https://www.latimes.com/business/la-fi-china-us-tariffs-20180803-story.html",
    },
    {
      title: "Trump boasts about GDP",
      source: "Politico ",
      date: "Jul 30, 2018",
      logo: politico,
      url: "https://www.politico.com/newsletters/morning-money/2018/07/30/trump-boasts-about-gdp-300322",
    },
    {
      title: "U.S. Stocks Resilient in Trade Spat, Sparking Complacency Fears",
      source: "The Wall Street Journal",
      date: "Jul 15, 2018",
      logo: wsj,
      url: "https://www.wsj.com/articles/u-s-stocks-enjoy-haven-status-as-trade-spat-dings-other-markets-1531652400",
    },
    {
      title: "Welcome to Tariffs Day",
      source: "Politico",
      date: "Jul 06, 2018",
      logo: politico,
      url: "https://www.politico.com/newsletters/morning-money/2018/07/06/welcome-to-tariffs-day-272478",
    },
    {
      title: "The Art of (Trade) War",
      source: "Yahoo Finance",
      date: "Jun 19, 2018",
      logo: yahooFinance,
      url: "https://finance.yahoo.com/news/art-trade-war-200729974.html?guccounter=1",
    },
    {
      title:
        "GeoQuant CEO Appears on FNC’s ‘Shepard Smith Reporting’ Talking China, Tariffs",
      source: "Fox News",
      date: "Jun 06, 2018",
      logo: foxNews,
      url: "https://vimeo.com/275905142/7bbe3f9ddc",
    },
    {
      title:
        "While He Stays Silent, Others Have Tried To Define Robert Mueller's Persona",
      source: "NPR",
      date: "May 17, 2018",
      logo: npr,
      url: "https://www.npr.org/2018/05/17/612082788/while-he-stays-silent-others-have-tried-to-define-robert-muellers-persona",
    },
    {
      title:
        "The Mueller Risk Index: Wall Street's newest tool to navigate how much to freak out about the Russia probe",
      source: "Business Insider",
      date: "Apr 25, 2018",
      logo: businessInsider,
      url: "https://www.businessinsider.com/mueller-risk-index-wall-street-trump-russia-political-risk-2018-4",
    },
    {
      title: "Meet the Mueller Risk Index, Wall Street's newest fear gauge",
      source: "CNN",
      date: "Feb 27, 2018",
      logo: cnn,
      url: "https://money.cnn.com/2018/02/27/investing/mueller-index-trump-stock-market/index.html",
    },
    {
      title: "An Artificial Intelligence Take on 2018",
      source: "Axios",
      date: "Jan 15, 2018",
      logo: axios,
      url: "https://www.axios.com/2018-in-geopolitics-according-to-a-bot-1515888269-40279cd9-5a7c-462d-b3e1-d4ceed37a74b.html",
    },
    {
      title: "Wars and viruses: Are robots less prone to market panic?",
      source: "Reuters",
      date: "Jan 31, 2020",
      logo: reuters,
      url: "https://www.reuters.com/article/us-china-health-algos-insight-idUKKBN1ZU1Q3",
    },
    {
      title:
        "Supreme Court Chaos Is a Symptom of America’s Institutional Decay",
      source: "Barron's",
      date: "Sep 22, 2020",
      logo: barrons,
      logoWidth: 150,
      url: "https://www.barrons.com/articles/supreme-court-chaos-is-a-symptom-of-americas-institutional-decay-51600815440",
    },

    {
      title: "Investors ignore Afghanistan, but risk levels are on the rise",
      source: "MarketWatch",
      date: "Aug 23, 2021",
      logo: marketWatch,
      url: "https://www.marketwatch.com/story/how-afghanistan-woes-raise-risk-level-even-as-global-financial-markets-ignore-the-chaos-11629757374",
    },
    {
      title: "What growing U.S. political risks mean for the markets",
      source: "Yahoo Finance",
      date: "Jan 15, 2021",
      logo: yahooFinance,
      url: "https://finance.yahoo.com/video/growing-u-political-risks-mean-194246232.html",
    },
    {
      title: "SA's political risk to increase early August",
      source: "Newzroom Afrika",
      date: "Jul 18, 2021",
      logo: NewzRoomAfrika,
      logoWidth: 150,
      url: "https://www.youtube.com/watch?v=8zxosJepgwo",
    },
    {
      title:
        "Expert on Reddit-fueled trading: 'Large segments of the population are losing faith in our traditional institutions'",
      source: "Yahoo Finance",
      date: "Feb 2, 2021",
      logo: yahooFinance,
      url: "https://finance.yahoo.com/video/expert-reddit-fueled-trading-large-184805904.html",
    },
    {
      title: "Investors Are Already Treating America Like an Emerging Market",
      source: "Foreign Policy",
      date: "Oct 13, 2020",
      logo: foreignPolicy,
      url: "https://foreignpolicy.com/2020/10/13/election-trump-emerging-market-investors/",
    },
  ]
);

export default Press;
